



















import { Vue, Component } from "vue-property-decorator";
import { Form, FormItem, RadioGroup, Radio } from "element-ui";
import FormItemBox from "@/components/sign/FormItemBox.vue";
import ProjectInfo from "@/components/assess/ProjectInfo.vue";
import AssessForm from "@/components/assess/AssessForm.vue";
import { VideoPlayer } from "@/components/al/videoPlayer";
@Component({
    components: {
        "el-form": Form,
        "el-form-item": FormItem,
        "el-radio-group": RadioGroup,
        "el-radio": Radio,
        "al-form-item": FormItemBox,
        ProjectInfo,
        AssessForm,
        "video-player": VideoPlayer,
    },
})
export default class Score extends Vue {
    record_id: string | number = this.$route.params.record_id;

    form = {
        resource: "",
    };

    formOpt: any = []; //表单项参数
    formData: any = {}; //表单值

    project: {} = {};

    assess_des: {} = {};

    /**获取项目数据 */
    getData(record_id: string | number) {
        // console.log(record_id);
        const getOpt = this.$api.apiList.getAssessProject;
        const opt = getOpt(record_id);

        this.$axios({
            url: opt.url,
            method: opt.method,
            headers: opt.headers,
        }).then((res: any) => {
            this.project = res.data.data.project_info;
            // this.formOpt = JSON.parse(res.data.data.reviewinfo.form_field);
            this.setForm(JSON.parse(res.data.data.reviewinfo.form_field));
            this.assess_des = {
                zh: res.data.data.reviewinfo.describe_cn,
                en: res.data.data.reviewinfo.describe_en,
            };
        });
    }

    /**设置表单参数，及初始值 */
    setForm(formOpt: any) {
        if (Object.keys(formOpt).length == 0) {
            this.formOpt = [];
            return;
        }

        for (const key in formOpt) {
            if (formOpt.hasOwnProperty(key)) {
                const element = formOpt[key];
                element.ref = element.formkey;
                this.$set(this.formData, element.formkey, element.value);
            }
        }

        this.formOpt = formOpt;
    }

    getFormOpt() {}

    mounted() {
        this.getData(this.record_id);
    }
}
