


























import { Vue, Component, Prop } from "vue-property-decorator";
import "@/components/al/ImportJs";
@Component({})
export default class VideoPlayer extends Vue {
    @Prop({ type: String, required: true, default: () => "" })
    url: any;

    @Prop({
        required: false,
        default: () => undefined,
    })
    cover: any;

    @Prop({
        required: false,
        default: () => undefined,
    })
    list: any;

    playerId = "video_player"; //视频播放器id

    player: any;
    actived: number = 0;

    //初始化视频播放器
    setVideo(playerId: any, url: string, coverImg?: string, mute?: boolean) {
        //初始化视频
        // @ts-ignore
        this.player = new Aliplayer(
            {
                id: playerId,
                width: "100%",
                height: "100%",
                autoplay: false,
                cover: coverImg ? coverImg : undefined,
                source: url,
                isLive: false,
                rePlay: true,
                playsinline: true,
                preload: true,
                controlBarVisibility: "hover",
                useH5Prism: true,
                autoPlayDelay: 1,
                waitingTimeout: 240,
            },
            function (player: any) {
                // console.log("关于ITCP培训模块，视频播放器创建好了。");
                if (mute) {
                    player.mute();
                }
                player.setVolume(0.35);
            }
        );
    }

    //外部js文件加载完毕钩子
    isLoaded(e: any) {
        console.log(this.list);

        if (this.list) {
            this.setVideo(this.playerId, this.list[this.actived].path);
        } else {
            this.setVideo(this.playerId, this.url, this.cover);
        }
    }

    /**点击视频列表选项
     * @param item 视频对象
     * @param index 列表数组的key
     */
    handleVideoItem(item: any, index: any) {
        this.player.loadByUrl(item.path);
        this.actived = index;
        this.player.play();
    }
}
